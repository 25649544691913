import React from 'react'
import { Link } from 'react-router-dom';

const NavBar = () => (
    <nav className="topnav" id="myTopnav">
        <div className="topnav-right">
            <Link to="/">Inicio</Link>
            <Link to="/about">Nosotros</Link>
            <Link to="/contact">Contacto</Link>

            {/* <a href="javascript:void(0);" className="icon" >
                <i className="fa fa-bars"></i>
            </a> */}
        </div>

    </nav>
);

export default NavBar;