const { compose, withProps } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
} = require("react-google-maps");

const MapBusRoute = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCHAny2XhL3jq1Gml_xlinSOyjSVsgzg7Y&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `600px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(props =>
  <GoogleMap defaultZoom={13} defaultCenter={new window.google.maps.LatLng(32.62430553083465, -115.454922390284)}>
    {props.directions && <DirectionsRenderer directions={props.directions} />}
    {/* <Marker position={{ lat: 32.643408977399844, lng: -115.45161395808299 }} draggable={true} opacity={0.2} /> */}
  </GoogleMap>
);

export default MapBusRoute;