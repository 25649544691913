import React from 'react';

const NotFoundPage = () => (
    <>
    <div className="card">
        <h1 className="card">Error 404</h1>
        <div className="barra"></div>
        <p>
            Sitio no encontrado
        </p>
    </div>
    </>
)

export default NotFoundPage;