import React from 'react';
import {
  BrowserRouter as Router, 
  Route,
  Switch,
} from 'react-router-dom';
import Index from './../pages/Index';
import AboutPage from './../pages/AboutPage';
import ContactPage from './../pages/ContactPage';
import NavBar from './NavBar';
import Footer from './Footer';
import NotFoundPage from './../pages/NotFoundPage';

function App() {
  return (
    <Router>
      <div className="App page-container">
        <NavBar/>
        <div className="content-wrap">
          <Switch>
            <Route path="/" component={Index} exact></Route>
            <Route path="/about" component={AboutPage} ></Route>
            <Route path="/contact" component={ContactPage} ></Route>
            <Route component={NotFoundPage} ></Route> 
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>

  );
}

export default App;
