import React from 'react';


const ContactPage = () => (
    <>
    <div className="card">
        <h1 className="card">Contacto</h1>
        <div className="barra"></div>
        <p>
            Mexicali Bus busca crecer con su público y usuarios, 
            te invitamos a que nos apoyes y no dudes en 
            contactarnos por alguna de las siguientes razones:
        </p>
        <ul>
            <li>Sugerencias</li>
            <li>Comentarios</li>
            <li>Reportar un cambio en alguna ruta</li>
            <li>Reportar una ruta inactiva</li>
            <li>Reportar un cambio en el nombre de alguna ruta</li>
        </ul>

        <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 24 24">
            <path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 
            7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 
            6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z"/>
        </svg>
        <h3 className="text-break"><a href="mailto:javierrazzzo@gmail.com">javierrazzzo@gmail.com</a></h3>

    </div>
    </>
)

export default ContactPage;
