import React from 'react';

const AboutPage = () => (
    <>
    <div className="card">
        <h1 className="card">Sobre nosotros</h1>
        <div className="barra"></div>
        <p>
            Mexicali Bus es un proyecto que busca mejorar la calidad 
            del servicio de transporte público, brindando información 
            útil y necesaria para el usuario acerca de las rutas de 
            transporte de los autobuses en Mexicali.
        </p>
        <p>
            El destino al que necesitas llegar puede estar al alcance de un pasaje, Mexicali Bus te ayudará a saber cual es
        </p>
    </div>
    </>
)

export default AboutPage;
