import React, {Component, } from 'react';
import MapBusRoute from './MapBusRoute';
// import MapWithADirectionsRenderer from './MapWithADirectionsRenderer';

class RouteInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            route: {},
            routes: [],
            directions: {},
            // DirectionsService: new window.google.maps.DirectionsService(),
        };
    
        // This binding is necessary to make `this` work in the callback
        this.handleChange = this.handleChange.bind(this);
        this.findRouteByID = this.handleChange.bind(this);
    }


    handleChange(event) {
            this.setState({route: this.state.routes.find(item => item.id === event.target.value)}, () => {

                const DirectionsService = new window.google.maps.DirectionsService();
                DirectionsService.route({
                    origin: new window.google.maps.LatLng(this.state.route.start.lat, this.state.route.start.lng),
                    destination: new window.google.maps.LatLng(this.state.route.end.lat, this.state.route.end.lng),
                    waypoints: this.state.route.waypoints.map( waypoint  => {
                        return {
                            location: new window.google.maps.LatLng(waypoint.lat, waypoint.lng),
                            stopover: waypoint.stopover}
                        }),
                    
                    travelMode: window.google.maps.TravelMode.DRIVING,
                }, (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        this.setState({ directions: result });
                    } 
                });

            });
    }

    componentDidMount() {
        fetch('./data.json')
            .then(response => response.json())
            .then(result => {
                const routes = result.map(item => {
                    return item;
                });
                this.setState({
                    routes: routes,
                    route: routes[0],
                })
            })
    }
    
    render() {
        return(
            <div>
                <div className="map-info">
                    <label htmlFor="ruta">
                        Ruta: 
                    </label>
                    <select value={this.state.value} onChange={this.handleChange} id="ruta">
                        {/* <option value="0">Select a route</option> */}
                        {this.state.routes.map(item => (
                            <option value={item.id} key={item.id}>{item.id} - {item.nombre}</option>
                            ))
                        }
                    </select>

                    {this.state.route.id !== "00"
                        ?<h2>{this.state.route.id} - {this.state.route.nombre}</h2> 
                        : <></>
                    }
                </div>

                <div className="shadow">
                    {this.state.directions 
                    ? <MapBusRoute directions={this.state.directions} />
                    : <MapBusRoute directions={this.state.directions} />}
                </div>

            </div>
        );
    }
}

export default RouteInfo;

